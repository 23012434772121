import { useEffect, useState } from "react";
import FacilitiesContext from "./FacilitiesContext";
import { SelectedFacility } from "services/facility/FacilityInterface";
import {
  DEFAULT_FACILITY,
  FacilityStorage,
} from "utils/storage/FacilityStorage";

export const FacilitiesProvider: React.FC<{
  children: React.ReactNode;
}> = ({ children }) => {
  const [facilities, setFacilities] = useState<string[]>([]);
  const [selectedFacility, setSelectedFacilityState] =
    useState<SelectedFacility | null>(null);

  const loadFacilities = () => {
    if (facilities.length === 0) {
      const storedFacilities = FacilityStorage.getFacilities();
      setFacilities(storedFacilities);

      let storedSelectedFacility =
        FacilityStorage.getSelectedFacility<SelectedFacility>();

      // If no selected facility, set the default HQ facility
      if (!storedSelectedFacility) {
        storedSelectedFacility = DEFAULT_FACILITY;
        FacilityStorage.setSelectedFacility(DEFAULT_FACILITY);
      }

      setSelectedFacilityState(storedSelectedFacility);
    }
  };

  const setSelectedFacility = (selectedFacility: SelectedFacility) => {
    setSelectedFacilityState(selectedFacility);
    FacilityStorage.setSelectedFacility(selectedFacility);
  };

  useEffect(() => {
    loadFacilities();
  }, []);

  const contextValue = {
    facilities,
    selectedFacility,
    setFacilities: (newFacilities: string[]) => {
      setFacilities(newFacilities);
      FacilityStorage.setFacilities(newFacilities);
    },
    setSelectedFacility,
    loadFacilities,
  };

  return (
    <FacilitiesContext.Provider value={contextValue}>
      {children}
    </FacilitiesContext.Provider>
  );
};
