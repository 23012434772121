import { getGlobalLogout } from 'context/user/UserProvider';
import { showAlertGlobal } from '../../context/alert/AlertProvider';

export const responseInterceptorError = (error: any) => {
  if (error.code === 'ERR_NETWORK') {
    const logout = getGlobalLogout();
    if (logout) logout();

    showAlertGlobal('Network Error: Unable to reach the server.', 'error');
    return Promise.resolve();
  }
  const { response } = error;
  const statusCode = response?.status;

  switch (statusCode) {
    case 403:
      showAlertGlobal(
        'Forbidden: You do not have permission to perform this action.',
        'error'
      );
      break;

    case 500:
      showAlertGlobal(
        'Server Error: Something went wrong on our end.',
        'error'
      );
      break;

    case 503:
      showAlertGlobal('Service Unavailable: Please try again later.', 'error');
      break;

    default:
      break;
  }

  if ([403, 500, 503].includes(statusCode)) {
    return Promise.resolve();
  }
  return Promise.reject(error.response.data);
};
