import ConfirmDialogContext, {
  ConfirmOptions,
} from 'context/confirmDialog/ConfirmDialogContext';
import { useContext } from 'react';

export const useConfirm = (): ((
  options: ConfirmOptions
) => Promise<boolean>) => {
  const context = useContext(ConfirmDialogContext);
  if (!context) {
    throw new Error('useConfirm must be used within a ConfirmDialogProvider');
  }
  return context.confirm;
};
