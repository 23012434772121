import { Button } from "@progress/kendo-react-buttons";
import React, { useCallback, useEffect, useRef, useState } from "react";
import {
  menuIcon,
  userIcon,
  clipboardIcon,
  gearIcon,
  setColumnPositionIcon,
  layoutStackedIcon,
} from "@progress/kendo-svg-icons";
import {
  Drawer,
  DrawerContent,
  DrawerSelectEvent,
} from "@progress/kendo-react-layout";
import { Outlet, useNavigate } from "react-router-dom";
import "./Layout.scss";
import { Popup } from "@progress/kendo-react-popup";
import { useUser } from "../../hooks/useUser";
import FacilitySelect from "components/facilitySelect/FacilitySelect";
import ChangePasswordContainer from "pages/auth/change-password/ChangePasswordContainer";
import { UserStorage } from "utils/storage/UserStorage";
import { IUser } from "routes";

const items = [
  {
    text: "Dashboard",
    svgIcon: layoutStackedIcon,
    selected: true,
    route: "/app",
  },
  { text: "Users", svgIcon: userIcon, route: "/app/users" },
  { text: "Reports", svgIcon: clipboardIcon, route: "/app/reports" },
  { text: "UMM", svgIcon: setColumnPositionIcon, route: "/app/umm" },
  { text: "Settings", svgIcon: gearIcon, route: "/app/settings" },
];

const DrawerRouterContainer: React.FC = () => {
  const { logout } = useUser();
  const anchorRef = useRef<Button | null>(null);

  const [show, setShow] = React.useState(false);
  const [showChangePassword, setShowChangePassword] = React.useState(false);

  const navigate = useNavigate();
  const [expanded, setExpanded] = React.useState(true);
  const [selected, setSelected] = React.useState(
    items.findIndex((x) => x.selected === true)
  );
  const [userInitials, setUserInitials] = useState<string>("");

  useEffect(() => {
    const storedUser: IUser | null = UserStorage.getUser();
    if (storedUser) {
      const firstInitial = storedUser.firstName
        ? storedUser.firstName.charAt(0).toUpperCase()
        : "";
      const lastInitial = storedUser.lastName
        ? storedUser.lastName.charAt(0).toUpperCase()
        : "";
      setUserInitials(`${firstInitial}${lastInitial}`);
    }
  }, []);

  const handleClick = () => {
    setExpanded(!expanded);
  };

  const onSelect = (e: DrawerSelectEvent) => {
    navigate(e.itemTarget.props.route);
    setSelected(e.itemIndex);
  };

  const onLogout = () => {
    logout();
  };

  const onClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    setShow(!show);
  };

  const changePassword = () => {
    setShowChangePassword((prevState) => !prevState);
  };

  const handleBodyClick = useCallback(
    (event: MouseEvent) => {
      if (
        show &&
        anchorRef.current &&
        anchorRef.current.element &&
        !anchorRef.current.element.contains(event.target as Node)
      ) {
        setShow(false);
      }
    },
    [show]
  );

  const toggleChangePasswordDialog = () => {
    setShowChangePassword(!showChangePassword);
  };

  useEffect(() => {
    document.body.addEventListener("click", handleBodyClick);
    return () => {
      document.body.removeEventListener("click", handleBodyClick);
    };
  }, [handleBodyClick]);

  return (
    <div>
      <div className="custom-toolbar d-flex align-items-center justify-content-between p-2 ">
        <div className="d-flex align-items-center">
          <Button svgIcon={menuIcon} fillMode="flat" onClick={handleClick} />
          <span className="mail-box ms-3 me-5">Sensorsuite</span>
          <div className="ms-5">
            <FacilitySelect />
          </div>
        </div>

        <div className="d-flex align-items-center me-2">
          <Button
            themeColor={"primary"}
            size={"small"}
            onClick={onClick}
            ref={anchorRef}
          >
            {userInitials}
          </Button>
          <Popup
            anchor={anchorRef.current?.element}
            show={show}
            popupClass={"popup-content"}
          >
            <div className="popup-menu">
              <div className="popup-item">Profile</div>
              <div className="popup-item" onClick={changePassword}>
                Change Password
              </div>
              <div className="popup-item" onClick={onLogout}>
                Logout
              </div>
            </div>
          </Popup>
        </div>
      </div>
      <Drawer
        expanded={expanded}
        position={"start"}
        mode={"push"}
        mini={true}
        items={items.map((item, index) => ({
          ...item,
          selected: index === selected,
        }))}
        onSelect={onSelect}
        style={{ height: "100vh" }}
      >
        <DrawerContent className="content vh-100">
          <Outlet />
        </DrawerContent>
      </Drawer>
      <ChangePasswordContainer
        show={showChangePassword}
        toggleDialog={toggleChangePasswordDialog}
      />
    </div>
  );
};

export default DrawerRouterContainer;
