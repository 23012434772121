import { SelectedFacility } from 'services/facility/FacilityInterface';
import StorageUtils from './StorageUtils';

export const DEFAULT_FACILITY: SelectedFacility = {
  facilityId: 'HQ',
  facilityName: 'HQ',
  facilityCode: 'HQ',
};

export const FacilityStorage = {
  getFacilities(): string[] {
    return StorageUtils.getItem<string[]>('facilities') || [];
  },

  setFacilities(facilities: string[]): void {
    StorageUtils.setItem('facilities', facilities);
  },

  getSelectedFacility<T>(): T | null {
    return StorageUtils.getItem<T>('selectedFacility');
  },

  setSelectedFacility<T>(facility: T): void {
    StorageUtils.setItem('selectedFacility', facility);
  },
};

export const transformToSelectedFacility = (
  facilityId: string,
  facilityName: string,
  facilityCode: string
): SelectedFacility => ({
  facilityId: facilityId,
  facilityName: facilityName,
  facilityCode: facilityCode,
});
