const endpoints = {
  auth: {
    login: 'auth', // skip facility prefix
  },
  otp: {
    regenerateOtp: () => `otp/regenerate`,
    validateOtp: () => `otp/validate`,
  },
  invitedUsers: {
    generateOtp: (inviteUserUID: string) =>
      `InvitedUsers/${inviteUserUID}/GenerateOTP`,
    verifyOtp: (inviteUserUID: string) =>
      `InvitedUsers/${inviteUserUID}/VerifyOTP`,
    invitedUsersDetails: (facilityCode: string, inviteUserUID: string) =>
      `facilities/${facilityCode}/Invitedusers/${inviteUserUID}`,
  },
  users: {
    invitation: 'users/inviteuser',
    validateOrGenerateUsername: 'users/validateorgenerateusername',
    registerUser: 'users/registeruser',
    forgotPassword: () => `users/forgotpassword`,
    resetPassword: (linkId: string) => `users/resetpassword/${linkId}`,
    changePassword: () => `users/changepassword`,
    userList: () => `users`,
    user: (userId: number) => `users/${userId}`,
  },
  facilities: {
    facilities: 'Facilities', // skip facility prefix
    sensorsuiteConfig: () => `Sensorsuiteconfig`,
  },
  sensorSuiteProvisioning: {
    generateQRCode: (facilityId: string) =>
      `SensorSuiteprovisioning/${facilityId}`,
  },
  links: {
    getUniqueLinkInfo: (linkGuid: string) => `links/${linkGuid}`,
  },
};

export default endpoints;
