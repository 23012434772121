import StorageUtils from './StorageUtils';

export const UserStorage = {
  getUser<T>(): T | null {
    return StorageUtils.getItem<T>('user');
  },

  setUser<T>(user: T): void {
    StorageUtils.setItem('user', user);
  },

  removeUser(): void {
    StorageUtils.removeItem('user');
  },
};
