import apiClient from 'api/ApiClient';
import { GetResponse } from 'services/auth/AuthInterface';

export const facilityService = {
  getFacilities: async (): Promise<any> => {
    try {
      // Pass empty string for facilities API
      const response = await apiClient.get<GetResponse>('');
      return response.data;
    } catch (error: any) {
      throw new Error(error.message || 'Something went wrong');
    }
  },
};
