import { useState } from "react";
import ConfirmDialogContext, { ConfirmOptions } from "./ConfirmDialogContext";
import { Dialog, DialogActionsBar } from "@progress/kendo-react-dialogs";
import { Button } from "@progress/kendo-react-buttons";
import ConfirmDialog from "components/confirmDialog/ConfirmDialog";

export const ConfirmDialogProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const [options, setOptions] = useState<ConfirmOptions | null>(null);
  const [resolver, setResolver] = useState<(value: boolean) => void>(
    () => () => {}
  );

  const confirm = (options: ConfirmOptions): Promise<boolean> => {
    setOptions(options);
    return new Promise<boolean>((resolve) => {
      setResolver(() => resolve);
    });
  };

  const handleClose = (result: boolean) => {
    setOptions(null);
    resolver(result);
  };

  return (
    <ConfirmDialogContext.Provider value={{ confirm }}>
      {children}
      {options && (
        <ConfirmDialog
          title={options.title || "Confirm"}
          message={options.message}
          onConfirm={() => handleClose(true)}
          onCancel={() => handleClose(false)}
        />
      )}
    </ConfirmDialogContext.Provider>
  );
};
