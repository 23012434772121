import {
  DropDownList,
  DropDownListChangeEvent,
} from "@progress/kendo-react-dropdowns";
import { useFacilities } from "hooks/useFacilities";
import { useEffect, memo } from "react";
import {
  Facility,
  SelectedFacility,
} from "services/facility/FacilityInterface";
import { transformToSelectedFacility } from "utils/storage/FacilityStorage";

const FacilitySelect: React.FC = () => {
  const { facilities, selectedFacility, setSelectedFacility, loadFacilities } =
    useFacilities();

  useEffect(() => {
    loadFacilities();
  }, [loadFacilities]);

  const handleFacilityChange = (event: DropDownListChangeEvent) => {
    const facility: Facility = event.value;
    const selectedFacility: SelectedFacility = transformToSelectedFacility(
      facility.facilityId,
      facility.facilityName,
      facility.facilityCode
    );
    setSelectedFacility(selectedFacility);
  };

  return (
    <>
      <DropDownList
        size={"small"}
        fillMode={"flat"}
        rounded={"medium"}
        data={facilities}
        textField="facilityName"
        dataItemKey="facilityId"
        onChange={handleFacilityChange}
        value={selectedFacility}
        style={{
          width: "300px",
        }}
      />
    </>
  );
};

export default memo(FacilitySelect);
