import React from "react";
import { Dialog, DialogActionsBar } from "@progress/kendo-react-dialogs";
import { Button } from "@progress/kendo-react-buttons";

interface ConfirmDialogProps {
  title: string;
  message: string;
  onConfirm: () => void;
  onCancel: () => void;
}

const ConfirmDialog: React.FC<ConfirmDialogProps> = ({
  title,
  message,
  onConfirm,
  onCancel,
}) => {
  return (
    <Dialog title={title} onClose={onCancel}>
      <p>{message}</p>

      <div className="d-flex justify-content-end mt-4 gap-2">
        <Button onClick={onCancel}>Cancel</Button>
        <Button onClick={onConfirm} themeColor="primary">
          Confirm
        </Button>
      </div>

      {/* <DialogActionsBar>
        <Button onClick={onConfirm} themeColor="primary">
          Yes
        </Button>
        <Button onClick={onCancel}>No</Button>
      </DialogActionsBar> */}
    </Dialog>
  );
};

export default ConfirmDialog;
