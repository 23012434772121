import jsQR from 'jsqr';

/**
 * Decodes a QR code from a given Blob (image file).
 *
 * This function reads an image from a Blob, renders it onto a canvas,
 * extracts pixel data, and attempts to decode the QR code.
 *
 * @param {Blob} response - The image Blob containing the QR code.
 * @returns {Promise<string | null>} A promise that resolves with the decoded QR code data
 *                                   if successful, or `null` if decoding fails.
 */
export const decodeQrCode = async (response: Blob): Promise<string | null> => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();

    reader.onload = async (event) => {
      if (!event.target?.result) {
        reject(new Error('Failed to load QR code image'));
        return;
      }

      const image = new Image();
      image.src = event.target.result as string;
      image.onload = () => {
        const canvas = document.createElement('canvas');
        const ctx = canvas.getContext('2d');
        if (!ctx) {
          reject(new Error('Failed to get canvas context'));
          return;
        }

        canvas.width = image.width;
        canvas.height = image.height;
        ctx.drawImage(image, 0, 0, image.width, image.height);

        const imageData = ctx.getImageData(0, 0, canvas.width, canvas.height);

        const code = jsQR(imageData.data, imageData.width, imageData.height);
        if (code) {
          resolve(code.data);
        } else {
          reject(new Error('Failed to decode QR Code'));
        }
      };
    };

    reader.readAsDataURL(response);
  });
};
