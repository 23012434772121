import { createContext } from 'react';

export interface ConfirmOptions {
  title?: string;
  message: string;
}

interface ConfirmDialogContextProps {
  confirm: (options: ConfirmOptions) => Promise<boolean>;
}

const ConfirmDialogContext = createContext<
  ConfirmDialogContextProps | undefined
>(undefined);

export default ConfirmDialogContext;
