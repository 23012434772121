import { useFacilities } from "hooks/useFacilities";
import Settings from "./Settings";
import { useEffect, useState } from "react";
import { settingsService } from "services/settings/SettingsService";
import { useAlert } from "context/alert/useAlert";
import { Dialog } from "@progress/kendo-react-dialogs";
import { useLoader } from "hooks/useLoader";
import { decodeToken } from "react-jwt";
import { decodeQrCode } from "utils/qrCodeUtils";

export interface SensorsuiteConfigDetail {
  cnoEmail: string;
  complianceDegree: number;
  enableCompliance: boolean;
  enableReports: boolean;
  enableTurnProtocol: boolean;
  facilityId: string;
  id: string;
  turnProtocol: string;
}

export interface ConfigFormState {
  complianceDegree: number;
  enableCompliance: boolean;
  enableReports: boolean;
  enableTurnProtocol: boolean;
  turnProtocol: string;
}

const SettingsContainer: React.FC = () => {
  const { selectedFacility } = useFacilities();
  const { showAlert } = useAlert();
  const [qrLoading, setQRLoading] = useState<boolean>(false);
  const [showQRCode, setShowQRCode] = useState<boolean>(false);
  const [hasConfig, setHasConfig] = useState<boolean>(false);
  const [qrCodeUrl, setQrCodeUrl] = useState<string | null>(null);
  const [qrCodeExpiry, setQrCodeExpiry] = useState<string>("");
  const { showLoader, hideLoader, isLoading } = useLoader();

  const [configFormState, setConfigFormState] = useState<ConfigFormState>({
    complianceDegree: 0,
    enableCompliance: false,
    enableReports: false,
    enableTurnProtocol: false,
    turnProtocol: "",
  });

  const onGenerateQRCode = async () => {
    if (!selectedFacility) {
      showAlert("No Facility Selected.", "error");
      return;
    }

    try {
      setQRLoading(true);
      const response: Blob = await settingsService.generateQRCode(
        selectedFacility.facilityId
      );
      const imageUrl = URL.createObjectURL(response);
      setQrCodeUrl(imageUrl);
      extractQrCodeExpiry(response);
      setShowQRCode(true);
    } catch (error: any) {
      showAlert(error.message, "error");
    } finally {
      setQRLoading(false);
    }
  };

  const extractQrCodeExpiry = async (response: Blob) => {
    const qrData: any = await decodeQrCode(response);
    if (qrData) {
      const decodedToken = decodeToken<any>(qrData);
      const expiration = new Date(decodedToken.exp * 1000).toLocaleString();
      setQrCodeExpiry(expiration);
    } else {
      showAlert("Invalid QR Code", "error");
    }
  };

  const closeDialog = () => {
    setShowQRCode(false);
    if (qrCodeUrl) {
      URL.revokeObjectURL(qrCodeUrl);
      setQrCodeUrl(null);
    }
  };

  useEffect(() => {
    const fetchSensorsuiteConfig = async () => {
      try {
        showLoader();
        const response: SensorsuiteConfigDetail =
          await settingsService.fetchSensorsuiteConfig();
        if (response) {
          setHasConfig(true);
          setConfigFormState({
            complianceDegree: response.complianceDegree,
            enableCompliance: response.enableCompliance,
            enableReports: response.enableReports,
            enableTurnProtocol: response.enableTurnProtocol,
            turnProtocol: response.turnProtocol,
          });
        }
      } catch (error: any) {
        setHasConfig(false);
        showAlert(error.message, "error");
      } finally {
        hideLoader();
      }
    };

    fetchSensorsuiteConfig();
  }, [selectedFacility]);

  useEffect(() => {}, []);

  const handleInputChange = (
    fieldName: keyof ConfigFormState,
    value: string
  ) => {
    setConfigFormState((prevState: any) => ({
      ...prevState,
      [fieldName]: value ?? "",
    }));
  };

  const onConfigUpdate = async () => {
    showLoader();
    try {
      const response = await settingsService.udpateSensorsuiteConfig(
        configFormState
      );
      if (response) {
        showAlert("Configuration updated successfully.", "success");
      }
    } catch (error: any) {
      showAlert(error.message, "error");
    } finally {
      hideLoader();
    }
  };

  return (
    <>
      {hasConfig && selectedFacility && (
        <Settings
          onInputChange={handleInputChange}
          onSubmit={onConfigUpdate}
          onGenerateQRCode={onGenerateQRCode}
          qrLoading={qrLoading}
          configFormState={configFormState}
          selectedFacility={selectedFacility}
        />
      )}
      {showQRCode && (
        <Dialog title={"Device Enrollment Code"} onClose={closeDialog}>
          {qrCodeUrl && (
            <>
              <div className="text-center">
                <img
                  src={qrCodeUrl}
                  alt="QR Code"
                  style={{ maxWidth: "350px", maxHeight: "400px" }}
                />
              </div>
              <div
                className="mt-2 me-2"
                style={{
                  textAlign: "right",
                }}
              >
                <strong className="me-2">Expires:</strong>
                <span>{qrCodeExpiry}</span>
              </div>
            </>
          )}
        </Dialog>
      )}
      {!hasConfig && !isLoading && (
        <div className="alert alert-light m-3" role="alert">
          It seems like there are no configurations set for this facility yet.
        </div>
      )}
    </>
  );
};

export default SettingsContainer;
