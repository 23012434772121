import { Button, Chip } from "@progress/kendo-react-buttons";
import React from "react";
import {
  filterIcon,
  pencilIcon,
  searchIcon,
  linkAddIcon,
} from "@progress/kendo-svg-icons";
import {
  Grid,
  GridColumn,
  GridPageChangeEvent,
  GridSortChangeEvent,
} from "@progress/kendo-react-grid";
import UsersDialogContainer from "./usersDialog/UsersDialogContainer";
import { Facility } from "services/facility/FacilityInterface";
import { User } from "./interfaces/UsersInterface";
import { InputPrefix, Switch, TextBox } from "@progress/kendo-react-inputs";
import { SvgIcon } from "@progress/kendo-react-common";
import { DropDownList } from "@progress/kendo-react-dropdowns";
import UserEditDialogContainer from "./userEditDialog/UserEditDialogContainer";
import { useConfirm } from "hooks/useConfirm";
import { useUser } from "hooks/useUser";

interface UsersProps {
  facilities: Array<Facility>;
  fetchUsers: () => Promise<void>;
  handleUserStatusUpdate: (userId: number, status: boolean) => Promise<void>;
  users: Array<User>;
  totalPages: number;
  totalCount: number;
  currentPage: number;
  pageSize: number;
  statusFilter: string;
  onSearch: (query: string) => void;
  onPageChange: (page: number, pageSize: number) => void;
  sortField: string;
  sortOrder: string;
  onSortChange: (sortField: string, sortOrder: "asc" | "desc") => void;
  onStatusChange: (statusValue: string) => void;
}

const Users: React.FC<UsersProps> = ({
  facilities,
  fetchUsers,
  handleUserStatusUpdate,
  users,
  totalPages,
  totalCount,
  currentPage,
  pageSize,
  statusFilter,
  onSearch,
  onPageChange,
  sortField,
  sortOrder,
  onSortChange,
  onStatusChange,
}) => {
  const confirm = useConfirm();
  const { user } = useUser();

  const [visible, setVisible] = React.useState<boolean>(false);
  const [editDialogVisible, setEditDialogVisible] =
    React.useState<boolean>(false);
  const [activeUserId, setActiveUserId] = React.useState<number>();
  const statusOptions = ["All", "Active", "Inactive"];

  const statusCell = (props: any) => {
    const isActive = props.dataItem.is_active;
    return <td>{isActive ? "Active" : "Inactive"}</td>;
  };

  const usernameCell = (props: any) => {
    const username = props.dataItem.username;
    return (
      <td>
        {username}{" "}
        {user.username === username && (
          <Chip
            text="Self"
            size="small"
            themeColor="warning"
            fillMode="solid"
          />
        )}
      </td>
    );
  };

  const editCell = (props: any) => {
    const isActive = props.dataItem.is_active;
    const userId = props.dataItem.user_id;
    const username = props.dataItem.username;

    return (
      <td className="d-flex align-items-center gap-3">
        <Button
          icon="pencil"
          svgIcon={pencilIcon}
          themeColor="primary"
          size={"small"}
          fillMode={"clear"}
          disabled={user.username === username}
          onClick={() => {
            setEditDialogVisible(true);
            setActiveUserId(userId);
          }}
        />
        <Switch
          size={"small"}
          checked={isActive}
          onChange={(e: any) => {
            const newStatus = e.value;
            confirm({
              title: "Confirm Status Change",
              message: "Are you sure want to change the user's status?",
            }).then((result) => {
              if (result) {
                handleUserStatusUpdate(userId, newStatus);
              } else {
              }
            });
          }}
          onLabel="Active"
          offLabel="Inactive"
          disabled={user.username === username}
        />
      </td>
    );
  };

  const toggleDialog = () => {
    setVisible(!visible);
  };

  const toggleEditDialog = () => {
    setEditDialogVisible(!editDialogVisible);
  };

  const handleGridPageChange = (event: GridPageChangeEvent) => {
    const newPage = Math.floor(event.page.skip / event.page.take) + 1;
    const newPageSize = event.page.take;
    onPageChange(newPage, newPageSize);
  };

  const handleSort = (event: GridSortChangeEvent) => {
    if (event.sort.length > 0) {
      const newSortField = event.sort[0].field || "first_name";
      let newSortOrder: "asc" | "desc";

      if (newSortField === sortField) {
        newSortOrder = sortOrder === "asc" ? "desc" : "asc";
      } else {
        newSortOrder = "asc";
      }

      onSortChange(newSortField, newSortOrder);
    } else {
      const newSortOrder = sortOrder === "asc" ? "desc" : "asc";
      onSortChange(sortField, newSortOrder);
    }
  };

  return (
    <div className="mt-3">
      <div className="d-flex justify-content-between align-items-center">
        <h4 className="ms-5 text-secondary">Users</h4>
        <Button
          className="me-5"
          themeColor={"primary"}
          type="submit"
          svgIcon={linkAddIcon}
          onClick={toggleDialog}
        >
          Invite User
        </Button>

        {visible && (
          <UsersDialogContainer
            toggleDialog={toggleDialog}
            facilities={facilities}
          />
        )}

        {editDialogVisible && (
          <UserEditDialogContainer
            toggleEditDialog={toggleEditDialog}
            userId={activeUserId}
            fetchUsers={fetchUsers}
          />
        )}
      </div>

      <div className="d-flex ms-5 mt-3 me-5 align-items-center justify-content-between">
        <TextBox
          fillMode={"outline"}
          onChange={(e: any) => onSearch(e.value)}
          placeholder="Search by First, Last or Username"
          prefix={() => (
            <InputPrefix>
              <SvgIcon icon={searchIcon} />
            </InputPrefix>
          )}
          style={{
            width: 300,
          }}
        />
        <div className="d-flex align-items-center gap-2 ml-auto">
          <SvgIcon icon={filterIcon} />
          <label className="text-secondary">Status</label>
          <DropDownList
            data={statusOptions}
            value={statusFilter}
            onChange={(e: any) => onStatusChange(e.value)}
            style={{ width: 150 }}
            fillMode={"outline"}
          />
        </div>
      </div>

      <div className="bg-white reports-container ms-5 me-5">
        <Grid
          data={users}
          sortable
          sort={
            sortField
              ? [
                  {
                    field: sortField,
                    dir: sortOrder as "asc" | "desc" | undefined,
                  },
                ]
              : []
          }
          onSortChange={handleSort}
          pageable={{
            buttonCount: 4,
            pageSizes: [5, 10, 20],
            info: true,
            type: "numeric",
          }}
          total={totalCount}
          skip={(currentPage - 1) * pageSize}
          take={pageSize}
          onPageChange={handleGridPageChange}
        >
          <GridColumn field="first_name" title="First Name" sortable />
          <GridColumn field="last_name" title="Last Name" sortable />
          <GridColumn
            field="username"
            title="Username"
            sortable
            cell={usernameCell}
          />
          <GridColumn field="phone" title="Phone" sortable={false} />
          <GridColumn
            field="email"
            title="Email"
            sortable={false}
            width={300}
          />
          <GridColumn
            field="is_active"
            title="Status"
            sortable={false}
            cell={statusCell}
          />
          <GridColumn title="" cell={editCell} />
        </Grid>
      </div>
    </div>
  );
};

export default Users;
