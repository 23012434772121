import { useCallback, useEffect, useState } from "react";
import { PRIVILEGES_MOCKS } from "../../mocks/privileges";
import UserContext from "./UserContext";
import { IUser } from "../../routes";
import {
  DEFAULT_FACILITY,
  FacilityStorage,
} from "utils/storage/FacilityStorage";
import StorageUtils from "utils/storage/StorageUtils";
import { UserStorage } from "utils/storage/UserStorage";

type TUserProviderProps = {
  children: React.ReactNode | React.ReactFragment | React.ReactPortal;
};

let globalLogout: (() => void) | null = null;

export const setGlobalLogout = (logoutFn: () => void) => {
  globalLogout = logoutFn;
};

export const getGlobalLogout = () => globalLogout;

export const UserProvider: React.FC<TUserProviderProps> = ({ children }) => {
  const [user, setUser] = useState<IUser>({
    username: "",
    firstName: "",
    lastName: "",
    email: "",
    isAuthenticated: false,
    privileges: PRIVILEGES_MOCKS.read,
  });
  const [isInitialized, setIsInitialized] = useState(false);

  useEffect(() => {
    const storedUser = localStorage.getItem("user");
    if (storedUser) {
      setUser(JSON.parse(storedUser));
    } else {
      setUser({
        username: "",
        firstName: "",
        lastName: "",
        email: "",
        isAuthenticated: false,
        privileges: PRIVILEGES_MOCKS.read,
      });
    }
    setIsInitialized(true);
  }, []);

  const saveUser = (newUser: IUser) => {
    setUser(newUser);
    UserStorage.setUser(newUser);
  };

  const logout = useCallback(() => {
    setUser({
      username: "",
      firstName: "",
      lastName: "",
      email: "",
      isAuthenticated: false,
      privileges: PRIVILEGES_MOCKS.read,
    });
    StorageUtils.clearAll();
    FacilityStorage.setSelectedFacility(DEFAULT_FACILITY);
  }, []);

  useEffect(() => {
    setGlobalLogout(logout);
  }, [logout]);

  return (
    <UserContext.Provider value={{ user, saveUser, logout, isInitialized }}>
      {children}
    </UserContext.Provider>
  );
};
