import { createContext } from 'react';
import {
  Facility,
  SelectedFacility,
} from 'services/facility/FacilityInterface';

type FacilitiesContextType = {
  facilities: string[];
  selectedFacility: SelectedFacility | null;
  setSelectedFacility: (selectedFacility: SelectedFacility) => void;
  setFacilities: (facilities: string[]) => void;
  loadFacilities: () => void;
};

const FacilitiesContext = createContext<FacilitiesContextType | undefined>(
  undefined
);

export default FacilitiesContext;
