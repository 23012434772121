import { AxiosRequestHeaders, InternalAxiosRequestConfig } from 'axios';
import { getAuthToken } from '../../utils/TokenUtils';
import { FacilityStorage } from 'utils/storage/FacilityStorage';
import { SelectedFacility } from 'services/facility/FacilityInterface';
import { getFacilityCodeFromURL } from 'utils/UrlUtils';

export interface CustomAxiosRequestHeaders extends AxiosRequestHeaders {
  skipFacilityPrefix?: boolean;
}

/**
 * Intercepts Axios requests to:
 * - Attach Authorization token
 * - Dynamically append `facilities/{facilityCode}` unless skipped via header
 */
export const requestInterceptor = (
  config: InternalAxiosRequestConfig
): InternalAxiosRequestConfig | Promise<InternalAxiosRequestConfig> => {
  attachAuthToken(config);
  appendFacilityPrefix(config);
  return config;
};

/**
 * Attaches the Authorization token to the request headers.
 */
const attachAuthToken = (config: InternalAxiosRequestConfig): void => {
  const token = getAuthToken();
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }
};

/**
 * Dynamically appends `facilities/{facilityCode}` to the request URL
 * unless `skipFacilityPrefix` is set in the request headers.
 */
const appendFacilityPrefix = (config: InternalAxiosRequestConfig): void => {
  let facilityCode = getFacilityCodeFromURL();

  if (!facilityCode) {
    facilityCode = getFacilityCode();
  }

  const headers = config.headers as CustomAxiosRequestHeaders;

  if (facilityCode && !headers?.skipFacilityPrefix) {
    config.url = `facilities/${facilityCode}/${config.url}`;
  }

  delete headers?.skipFacilityPrefix;
};

/**
 * Retrieves the current facilityCode from storage.
 * @returns {string | undefined} facilityCode or undefined if not found
 */
const getFacilityCode = (): string | undefined => {
  const selectedFacility =
    FacilityStorage.getSelectedFacility<SelectedFacility>();
  return selectedFacility?.facilityCode;
};

export const requestInterceptorError = (error: any) => {
  return Promise.reject(error);
};
