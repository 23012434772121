import apiClient from 'api/ApiClient';
import { AuthResponse } from 'services/auth/AuthInterface';
import endpoints from 'api/Endpoints';

interface InvitationPayload {
  email: string;
  role: string;
  phone: string;
  facilityId: string;
}

export interface UserPayload {
  firstname?: string;
  lastname?: string;
  email?: string;
  phone?: string;
  is_active?: boolean;
}

export interface userDetailResponse {
  email: string;
  facilityCode: string;
  facilityId: string;
  facilityName: string;
  firstname: string;
  is_active: boolean;
  lastname: string;
  phone: string;
  username: string;
}

export interface FetchUsersParams {
  is_active?: boolean | undefined;
  page: number;
  pageSize: number;
  sortBy?: string;
  sortOrder?: 'asc' | 'desc';
  filterBy?: string;
  search?: string;
  username?: string;
}

export const userService = {
  inviteUser: async (invitationPayload: InvitationPayload): Promise<any> => {
    try {
      const response = await apiClient.post<AuthResponse>(
        endpoints.users.invitation,
        invitationPayload
      );
      return response;
    } catch (error: any) {
      throw new Error(error.message || 'Something went wrong');
    }
  },

  fetchUsers: async (
    facilityCode: string,
    params: FetchUsersParams
  ): Promise<any> => {
    try {
      const response = await apiClient.get<any>(endpoints.users.userList(), {
        params,
      });
      return response;
    } catch (error: any) {
      throw new Error(error.message || 'Something went wrong');
    }
  },

  fetchUserDetail: async (userId: number): Promise<any> => {
    try {
      const response = await apiClient.get<any>(endpoints.users.user(userId));
      return response;
    } catch (error: any) {
      throw new Error(error.message || 'Something went wrong');
    }
  },

  updateUser: async (userId: number, payload: UserPayload): Promise<any> => {
    try {
      const response = await apiClient.patch<any>(
        endpoints.users.user(userId),
        payload
      );
      return response;
    } catch (error: any) {
      throw new Error(error.message || 'Something went wrong');
    }
  },
};
