import {
  Notification,
  NotificationGroup,
  NotificationProps,
} from "@progress/kendo-react-notification";
import { useEffect } from "react";
import { Fade } from "@progress/kendo-react-animation";
import ReactDOM from "react-dom";

export type AlertType = "info" | "success" | "error" | "warning" | "none";

interface AlertComponentProps {
  message: string;
  type: AlertType | null;
  timeout?: number;
  onClose: () => void;
}

const AlertComponent: React.FC<AlertComponentProps> = ({
  message,
  type,
  timeout = 5000,
  onClose,
}) => {
  const styles: Record<AlertType, NotificationProps["type"]> = {
    info: { style: "info", icon: true },
    success: { style: "success", icon: true },
    error: { style: "error", icon: true },
    warning: { style: "warning", icon: true },
    none: { style: "none", icon: false },
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      onClose();
    }, timeout);
    return () => clearTimeout(timer);
  }, [onClose, timeout]);

  return ReactDOM.createPortal(
    <NotificationGroup
      style={{ right: 20, top: 20, position: "fixed", zIndex: 11000 }}
    >
      <Fade>
        {type && (
          <Notification type={styles[type]} closable onClose={onClose}>
            {message}
          </Notification>
        )}
      </Fade>
    </NotificationGroup>,
    document.body
  );
};

export default AlertComponent;
