import { useLoader } from "hooks/useLoader";
import Users from "pages/app/users/Users";
import { useEffect, useState } from "react";
import { Facility } from "services/facility/FacilityInterface";
import { facilityService } from "services/facility/FacilityService";
import { User } from "./interfaces/UsersInterface";
import { FetchUsersParams, userService } from "services/user/UserService";
import { useUser } from "hooks/useUser";
import { useAlert } from "context/alert/useAlert";
import { debounce } from "lodash";

const UsersContainer: React.FC = () => {
  const { user } = useUser();
  const { showLoader, hideLoader } = useLoader();
  const [facilities, setFacilities] = useState<Facility[]>([]);
  const { showAlert } = useAlert();

  const [users, setUsers] = useState<User[]>([]);
  const [totalPages, setTotalPages] = useState<number>(1);
  const [totalCount, setTotalCount] = useState<number>(0);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [pageSize, setPageSize] = useState<number>(10);
  const [searchQuery, setSearchQuery] = useState<string>("");
  const [statusFilter, setStatusFilter] = useState("All");

  const [sortField, setSortField] = useState<string>("created_at");
  const [sortOrder, setSortOrder] = useState<"asc" | "desc">("desc");

  useEffect(() => {
    fetchFacilities();
  }, []);

  const fetchFacilities = async () => {
    showLoader();
    try {
      const response: any = await facilityService.getFacilities();
      setFacilities(response);
    } catch (errror: any) {
    } finally {
      hideLoader();
    }
  };

  const fetchUsers = async () => {
    if (!user?.facility?.Code) {
      throw new Error("User facility is undefined or does not have a code.");
    }

    showLoader();
    try {
      // TODO: Replace with subdomain facility if needed
      const facilityCode = user.facility.Code;

      const params: FetchUsersParams = {
        page: currentPage,
        pageSize: pageSize,
        sortBy: sortField,
        sortOrder: sortOrder,
        // filterBy: searchQuery || undefined,
        search: searchQuery || undefined,
        ...(statusFilter !== "All" && {
          is_active: statusFilter === "Active" ? true : false,
        }),
      };

      const response: any = await userService.fetchUsers(facilityCode, params);
      if (response.data) {
        setUsers(response.data.items);
        setTotalPages(response.data.totalPages);
        setTotalCount(response.data.totalCount);
        setCurrentPage(response.data.currentPage);
        setPageSize(response.data.pageSize);
      }
    } catch (error: any) {
      console.error("Error fetching users:", error);
    } finally {
      hideLoader();
    }
  };

  useEffect(() => {
    fetchUsers();
  }, [currentPage, pageSize, searchQuery, statusFilter, sortOrder]);

  const handleSearchChange = debounce((query: string) => {
    setSearchQuery(query);
    setCurrentPage(1);
  }, 500);

  const handlePageChange = (newPage: number, newPageSize: number) => {
    if (newPage >= 1 && newPage <= totalPages) {
      setCurrentPage(newPage);
      setPageSize(newPageSize);
    }
  };

  const handleSortChange = (sortBy: string, sortOrder: "asc" | "desc") => {
    setSortField(sortBy);
    setSortOrder(sortOrder);
  };

  const handleStatusFilterChange = (statusFilterValue: string) => {
    setStatusFilter(statusFilterValue);
  };

  const handleUserStatusUpdate = async (userId: number, status: boolean) => {
    if (!user?.facility?.Code) {
      throw new Error("User facility is undefined or does not have a code.");
    }
    if (!userId) {
      throw new Error("User ID is missing.");
    }

    //TODO: Replace with subdomain facility
    const facilityCode = user.facility.Code;

    showLoader();
    try {
      const userStatusPayload = {
        is_active: status,
      };
      const response = await userService.updateUser(userId, userStatusPayload);
      if (response && response.status === "success") {
        fetchUsers();
        showAlert("Status updated successfully.", "success");
      }
    } catch (error: any) {
      showAlert(error.message, "error");
    } finally {
      hideLoader();
    }
  };

  return (
    <Users
      facilities={facilities}
      fetchUsers={fetchUsers}
      handleUserStatusUpdate={handleUserStatusUpdate}
      users={users}
      currentPage={currentPage}
      totalPages={totalPages}
      totalCount={totalCount}
      pageSize={pageSize}
      statusFilter={statusFilter}
      onSearch={handleSearchChange}
      onPageChange={handlePageChange}
      sortField={sortField}
      sortOrder={sortOrder}
      onSortChange={handleSortChange}
      onStatusChange={handleStatusFilterChange}
    />
  );
};

export default UsersContainer;
