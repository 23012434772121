import { FieldWrapper } from "@progress/kendo-react-form";
import {
  MaskedTextBox,
  MaskedTextBoxProps,
} from "@progress/kendo-react-inputs";
import { Label, Error } from "@progress/kendo-react-labels";

interface LabelMaskTextBoxProps extends MaskedTextBoxProps {
  label: string;
  id: string;
  name: string;
  showError?: boolean;
  componentProps: any;
}

const LabelMaskedInput: React.FC<LabelMaskTextBoxProps> = ({
  validationMessage,
  label,
  id,
  name,
  value,
  valid,
  disabled,
  onChange,
  mask,
  placeholder,
  componentProps = {},
  showError = false,
  ...rest
}) => {
  const showValidationMessage: string | false | null =
    validationMessage ?? null;

  return (
    <FieldWrapper>
      <Label
        className="k-form-label"
        editorId={id}
        editorValid={valid}
        editorDisabled={disabled}
      >
        {label}
      </Label>
      <div className="k-form-field-wrap">
        <MaskedTextBox
          id={id}
          name={name}
          value={componentProps.value}
          onChange={onChange}
          mask={mask}
          placeholder={placeholder}
          fillMode={"outline"}
          required={showError}
          {...rest}
        />

        {showValidationMessage && showError && (
          <Error>{validationMessage}</Error>
        )}
      </div>
    </FieldWrapper>
  );
};

export default LabelMaskedInput;
