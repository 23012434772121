import { Button } from "@progress/kendo-react-buttons";
import { Dialog } from "@progress/kendo-react-dialogs";
import {
  Field,
  Form,
  FormElement,
  FormRenderProps,
} from "@progress/kendo-react-form";
import LabelInput from "components/inputs/labelInput/LabelInput";
import LabelMaskedInput from "components/inputs/labelMaskedInput/LabelMaskedInput";
import {
  composeValidators,
  validateEmail,
  validateRequired,
} from "utils/Validators";
import { UserEditFormState } from "./UserEditDialogContainer";

interface UserEditDialogProps {
  userEditFormState: UserEditFormState;
  onInputChange: (fieldName: keyof UserEditFormState, value: string) => void;
  toggleEditDialog: () => void;
  onSubmit: () => void;
}

const UserEditDialog: React.FC<UserEditDialogProps> = ({
  userEditFormState,
  onInputChange,
  toggleEditDialog,
  onSubmit,
}) => {
  return (
    <>
      <Dialog
        title={"Edit User"}
        onClose={toggleEditDialog}
        width={800}
        height={500}
        autoFocus={false}
        autoFocusedElement=""
      >
        <Form
          initialValues={userEditFormState}
          onSubmit={onSubmit}
          render={(formRenderProps: FormRenderProps) => (
            <FormElement>
              <div className="row">
                <div className="col-md-6 mb-3">
                  <Field
                    id={"firstName"}
                    name={"firstName"}
                    label="First Name"
                    type={"text"}
                    placeholder="First Name"
                    component={LabelInput}
                    componentProps={{
                      value: userEditFormState.firstName,
                    }}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                      onInputChange("firstName", e.target.value);
                    }}
                    validator={composeValidators(validateRequired)}
                  />
                </div>

                <div className="col-md-6 mb-3">
                  <Field
                    id={"lastName"}
                    name={"lastName"}
                    label="Last Name"
                    type={"text"}
                    placeholder="Last Name"
                    component={LabelInput}
                    componentProps={{
                      value: userEditFormState.lastName,
                    }}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                      onInputChange("lastName", e.target.value);
                    }}
                    validator={composeValidators(validateRequired)}
                  />
                </div>
              </div>

              <div className="row">
                <div className="col-md-6 mb-3">
                  <Field
                    id={"email"}
                    name={"email"}
                    label="Email"
                    type={"text"}
                    placeholder="Email"
                    component={LabelInput}
                    componentProps={{
                      value: userEditFormState.email,
                    }}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                      onInputChange("email", e.target.value);
                    }}
                    validator={composeValidators(
                      validateRequired,
                      validateEmail
                    )}
                  />
                </div>

                <div className="col-md-6 mb-3">
                  <Field
                    id={"phone"}
                    name={"phone"}
                    label="Phone"
                    type={"text"}
                    component={LabelMaskedInput}
                    placeholder="(###)###-####"
                    mask="(999)000-0000"
                    value={userEditFormState.phone}
                    componentProps={{
                      value: userEditFormState.phone,
                    }}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                      onInputChange("phone", e.target.value);
                    }}
                    validator={validateRequired}
                    showError={
                      formRenderProps.touched && !formRenderProps.valid
                    }
                  />
                </div>
              </div>

              <div className="row">
                <div className="col-md-6 mb-3">
                  <Field
                    id={"facilityName"}
                    name={"facilityName"}
                    label="Facility"
                    component={LabelInput}
                    componentProps={{
                      value: userEditFormState.facilityName,
                    }}
                    placeholder="Select Facility"
                    disabled={true}
                  />
                </div>
                <div className="col-md-6 mb-3">
                  <Field
                    id={"username"}
                    name={"username"}
                    label="Username"
                    component={LabelInput}
                    componentProps={{
                      value: userEditFormState.username,
                    }}
                    placeholder="Select Facility"
                    disabled={true}
                  />
                </div>
              </div>
              <div className="d-flex justify-content-end mt-4">
                <Button
                  type="submit"
                  id="sendInvitation"
                  themeColor={"primary"}
                >
                  Update
                </Button>
              </div>
            </FormElement>
          )}
        ></Form>
      </Dialog>
    </>
  );
};

export default UserEditDialog;
