/**
 * Extracts the facility code from the browser URL.
 * - Assumes the subdomain follows the pattern `{facilityCode}.mobilitymonitor.com`
 * - Ensures the subdomain is exactly 6 characters long.
 * - Excludes reserved subdomains ("dev", "test", "prod").
 * @returns {string | undefined} The facility code if valid, otherwise undefined.
 */
export const getFacilityCodeFromURL = (): string | undefined => {
  try {
    const host = window.location.hostname;
    const subdomain = host.split('.')[0];

    const reservedSubdomains = new Set(['dev', 'test', 'prod']);

    return subdomain.length === 6 && !reservedSubdomains.has(subdomain)
      ? subdomain
      : undefined;
  } catch (error) {
    console.error('Error extracting facility code from URL:', error);
    return undefined;
  }
};
