import { useEffect, useState } from "react";
import UserEditDialog from "./UserEditDialog";
import { useLoader } from "hooks/useLoader";
import { useAlert } from "context/alert/useAlert";
import {
  userDetailResponse,
  UserPayload,
  userService,
} from "services/user/UserService";
import { useUser } from "hooks/useUser";

interface UserEditDialogContainerProps {
  toggleEditDialog: () => void;
  fetchUsers: () => Promise<void>;
  userId?: number;
}

export interface UserEditFormState {
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
  facilityName: string;
  username: string;
}

const UserEditDialogContainer: React.FC<UserEditDialogContainerProps> = ({
  toggleEditDialog,
  fetchUsers,
  userId,
}) => {
  const { user } = useUser();
  const { showLoader, hideLoader, isLoading } = useLoader();
  const { showAlert } = useAlert();

  const [userEditFormState, setUserEditFormState] = useState<UserEditFormState>(
    {
      firstName: "",
      lastName: "",
      email: "",
      phone: "",
      facilityName: "",
      username: "",
    }
  );

  useEffect(() => {
    if (userId) {
      if (!user?.facility?.Code) {
        throw new Error("User facility is undefined or does not have a code.");
      }

      const fetchUserDetail = async () => {
        showLoader();
        try {
          const response = await userService.fetchUserDetail(userId);

          if (response && response.data) {
            const userDetail: userDetailResponse = response.data;
            setUserEditFormState({
              firstName: userDetail.firstname || "",
              lastName: userDetail.lastname || "",
              email: userDetail.email || "",
              phone: userDetail.phone || "",
              facilityName: userDetail.facilityName || "",
              username: userDetail.username || "",
            });
          }
        } catch (error: any) {
          showAlert(error.message, "error");
        } finally {
          hideLoader();
        }
      };

      fetchUserDetail();
    }
  }, [userId]);

  const mapToUserPayload = (
    userEditFormState: UserEditFormState
  ): UserPayload => {
    const { firstName, lastName, email, phone } = userEditFormState;
    return {
      firstname: firstName,
      lastname: lastName,
      email: email,
      phone: phone,
    };
  };

  const handleUserUpdate = async () => {
    if (!user?.facility?.Code) {
      throw new Error("User facility is undefined or does not have a code.");
    }
    if (!userId) {
      throw new Error("User ID is missing.");
    }

    showLoader();
    try {
      const userPayload = mapToUserPayload(userEditFormState);
      const response = await userService.updateUser(userId, userPayload);
      if (response && response.status === "success") {
        fetchUsers();
        toggleEditDialog();
        showAlert("User updated successfully.", "success");
      }
    } catch (error: any) {
      showAlert(error.message, "error");
    } finally {
      hideLoader();
    }
  };

  const handleInputChange = (
    fieldName: keyof UserEditFormState,
    value: string
  ) => {
    setUserEditFormState((prevState: any) => ({
      ...prevState,
      [fieldName]: value,
    }));
  };

  return (
    <>
      {!isLoading && userEditFormState.username && (
        <UserEditDialog
          toggleEditDialog={toggleEditDialog}
          onSubmit={handleUserUpdate}
          onInputChange={handleInputChange}
          userEditFormState={userEditFormState}
        />
      )}
    </>
  );
};

export default UserEditDialogContainer;
